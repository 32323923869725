<template>
  <div class="imei-cont">
    <div class="banner">
      <img src="@/assets/img/banner.png" alt="">
    </div>
    <div class="box shadow form">
      <div class="form-group line">
        <div class="label">姓名：</div>
        <div class="cont">
          <cube-input :clearable="false" v-model.trim="form.name" :maxlength="18"></cube-input>
        </div>
      </div>
      <div class="form-group line">
        <div class="label">身份证号：</div>
        <div class="cont">
          <cube-input :clearable="false" v-model.trim="form.idcard" :maxlength="18" ></cube-input>
        </div>
      </div>
    </div>


    <cube-button class="btn" @click="search">确认修改</cube-button>

  </div>
</template>

<script>
export default {
  name: 'ScarchImei',
  data () {
    return {
      form: {
        name: '',
        idcard: '',
        policyIds: [],
      },
    }
  },
  mounted () {
    // 赋值
    const vm = this;
    let updateImei = window.sessionStorage.getItem('updateImei');
    if(updateImei){
      updateImei = JSON.parse(updateImei)
    }
    if(updateImei.imei == this.$route.params.imei){
      this.form.policyIds = updateImei.policyIds
    }else{
      this.$createDialog({
        type: 'alert',
        title: '',
        content: '当前IMEI未查询到信息',
        icon: ''
      }).show();
      setTimeout(() => {
        vm.$router.go(-1)
      }, 1500)
    }
  },
  methods: {
    /**
     * 输入内容验证
     */
    isImei () {
      if(this.form.name == ''){
        this.$createToast({
          txt: '请输入姓名',
          type: 'warn'
        }).show()
        return false;
      }else if(this.form.idcard == ''){
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '请输入身份证号码',
          icon: ''
        }).show()
        return false;
      }else if(! (/^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/.test(this.form.idcard))){ 
        this.$createDialog({
          type: 'alert',
          title: '',
          content: '身份证号码格式错误',
          icon: ''
        }).show()
        return false;
      }
      return true
    },
    /**
     * 查询
     */
    search () {
      const vm = this;
      if(! this.isImei()){
        return
      }
      let postData = {...this.form}
      vm.$store.commit('updateLoader', true);
      this.$http
        .post("/contract/updatePolicyName", postData)
        .then(function(response) {
          if (response.data.header.code == "A1000") {
            vm.$createToast({
              txt: '修改成功',
              type: 'success'
            }).show()
            setTimeout(() => {
              vm.$router.go(-1)
            }, 1500)
          } else {
            vm.$createToast({
              txt: response.data.header.msg,
              type: 'warn'
            }).show()
          }
          vm.$store.commit('updateLoader', false);
        })
        .catch(function() {
          vm.$store.commit('updateLoader', false);
          vm.$createToast({
            txt: '网络错误',
            type: 'error'
          }).show()
        });
    },
  },
}
</script>

<style lang="scss" scoped>
.imei-cont{
  min-height: 100vh;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 150px;
}
.banner{
  padding: 30px;
  img{
    display: block;
    width: 100%;
    border-radius: 14px;
  }
}
.box{
  margin: 0 30px 30px 30px;
  padding: 0 30px;
  .form-group{
    border-top: 1px solid #DEDEDE;
    &:first-child{
      border: none;
    }
  }
}
.form-group{
  font-size: 30px;
  color: #333333;
  line-height: 30px;
  .label{
    padding: 30px 0;
  }
  &.line{
    display: flex;
    .label{
      width: 170px;
    }
    .cont{
      flex: 10;
      text-align: right;
      input{
        display: block;
        width: 100%;
        text-align: right;
        padding: 30px 0;
        height: 30px;
        border: none;
        outline: none;
        list-style: none;
      }
    }
  }
}
.btn{
  width: 540px;
  height: 80px;
  padding: 0;
  line-height: 80px;
  background-color: #208170;
  border-radius: 50px;
  position: absolute;
  left: 105px;
  bottom: 50px;
}

.explain{
  text-align: right;
  padding: 30px;
  a{
    font-size: 26px;
    color: #E74857;
    text-align: center;
    line-height: 26px;
    display: flex;
  }
}
.explain-text{
  font-size: 26px;
  color: #E74857;
  line-height: 40px;
  text-align: center;
  padding: 0 50px;
}
.qe{
  display: block;
  height: 30px;
  margin-left: 10px;
}
.flex{
  display: flex;
  .flex-left{
    flex: 10;
  }
}
</style>